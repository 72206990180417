.meter {
    /* border: 2px solid green; */
    width: 8%;
    height: 4%;
    position: absolute;
    transition: opacity 0.3s;
    /* background-image: linear-gradient(to bottom,  rgba(226,226,226,0.1) 0%,rgba(219,219,219,0.1) 50%,rgba(209,209,209,0.1) 51%,rgba(254,254,254,0.1) 100%);
    box-shadow: inset 0 0 3px rgba(0 ,0, 0, 0.15), rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, rgb(170, 255, 255) 0 0px 4px, rgb(120, 244, 255, 0.7) 0 2px 40px; */
}
  
.shape {
    /* border: 2px solid green; */
    width: 13%;
    height: 13%;
    position: absolute;
}
  
.double {
    /* border: 2px solid green; */
    width: calc(26% + 2vw);
    height: 13%;
    position: absolute;
}

.left {
    position: relative;
    left: -26%;
    display: flex;
    justify-content: space-between;
}
  
.right {
    position: relative;
    /* left: 13%; */
    display: flex;
    justify-content: space-between;
}