.dial-container {
    /* border: 2px solid green; */
    width: 100%;
    padding-top: 100%;
    position: relative;
  }
  
  .dial-box {
    /* border: 2px solid red; */
    width: 70%;
    height: 70%;
    top: 15%;
    left: 15%;
    position: absolute;
    transition: transform 0s;
  }
  
  .dial-meter {
    /* border: 2px solid goldenrod; */
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
  }