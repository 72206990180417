

.dial-top {
    /* transition: 0.3s; */
    position: absolute;
    background-color: rgb(69,69,69);
    top: 10%;
    left: 10%;
    /* border: 2px solid rgba(116, 133, 165, 0.582); */
    border-radius: 50%;
    height: 80%;
    width: 80%;
    z-index: 997;
    background-image:
        radial-gradient(transparent, transparent 68%, black),
        linear-gradient(to right, rgba(0,0,0,0.1), rgba(0,0,0,0) 20%, rgba(255,255,255,0.1) 41%, rgb(0,0,0,1) 46%, rgb(0,0,0,1) 54%, rgba(255,255,255,0.1) 59%, rgba(0,0,0,0) 80%, rgba(0,0,0,0.1));
    box-shadow: 0 0 3vw rgba(0, 0, 0, 0.4), inset 0 0 0.5vw rgba(0, 0, 0, 0.6);
}

.dial-knob-overlay {
    /* border: 1px solid #ddd; */
    border-radius: 2%;
    position: absolute;
    top: -5%;
    /* top: 0; */
    left: 42%;
    height: 110%;
    width: 16%;
    z-index: 1000;
    background-color: transparent;
    background-image: linear-gradient(to top, rgba(255,255,255,0), rgba(255,255,255,0) 70%, rgba(255,255,255,0.3) 99%, rgba(0,0,0,0.3)),
    linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,0) 99%, rgba(0,0,0,0.3));
}

.dial-knob {
    /* border: 1px solid rgba(0,0,0,0.3); */
    /* border-left: 1px solid rgba(0,0,0,0.3);
    border-right: 1px solid rgba(0,0,0,0.3); */
    border-radius: 2%;
    position: relative;
    top: -5%;
    /* top: 0; */
    left: 42%;
    height: 110%;
    width: 16%;
    z-index: 999;
    background-color: rgb(69, 69, 69);
    box-shadow: 0 0 10px rgba(0 ,0, 0, 0.45);
    /* transition: 0.3s; */
    /* background-image: linear-gradient(to top, rgba(255,255,255,0), rgba(255,255,255,0) 70%, rgba(255,255,255,0.5) 99%, rgba(0,0,0,0.4) 100%); */
}

.dial-light {
    /* border: 2px solid rgb(0, 255, 34); */
    border-radius: 4%;
    background-color: rgb(125, 248, 255);
    position: relative;
    left: 32%;
    height: 30%;
    width: 36%;
    z-index: 1000;
    background-image: linear-gradient(to bottom,  rgba(226,226,226,0.1) 0%,rgba(219,219,219,0.1) 50%,rgba(209,209,209,0.1) 51%,rgba(254,254,254,0.1) 100%);
    box-shadow: inset 0 0 3px rgba(0 ,0, 0, 0.15), rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset rgb(0, 160, 160) 0 -1px 9px, rgb(170, 255, 255) 0 0px 8px, rgba(120, 244, 255, 0.85) 0 2px 40px;
}



.asi-dial {
    /* transition: 0.3s; */
    position: absolute;
    margin-top: 3%;
    margin-left: 3%;
    height: 94%;
    width: 94%;
}

.asi-top {
    /* transition: 0.3s; */
    position: absolute;
    /* background-color: rgb(35,35,35); */
    top: calc(24% - 1px);
    left: calc(24% - 1px);
    border: 1px solid rgba(0, 0, 0, 0.01);
    border-radius: 50%;
    height: 52%;
    width: 52%;
    z-index: 995;
    background-image: 
    radial-gradient(rgba(255,255,255,0.01), rgba(255,255,255,0.01) 55%, transparent 70%, transparent),
        radial-gradient(transparent, transparent 64%, black);
        /* linear-gradient(to right, rgba(0,0,0,0.1), rgba(0,0,0,0) 20%, rgba(255,255,255,0.1) 41%, rgb(0,0,0,1) 46%, rgb(0,0,0,1) 54%, rgba(255,255,255,0.1) 59%, rgba(0,0,0,0) 80%, rgba(0,0,0,0.1)); */
    box-shadow: 0 0 3vw rgba(0, 0, 0, 0.4), inset 0 0 1vw 1vw rgba(0,0,0, 0.3);
}

.asi-bottom {
    /* transition: 0.3s; */
    position: absolute;
    /* background-color: rgb(35,35,35); */
    top: calc(32% - 1px);
    left: calc(32% - 1px);
    border: 1px solid rgba(0, 0, 0, 0.01);
    border-radius: 50%;
    height: 36%;
    width: 36%;
    z-index: 995;
    background-image: 
        radial-gradient(rgba(255,255,255,0.03), rgba(255,255,255,0.03) 50%, transparent 70%, transparent),
        radial-gradient(transparent, transparent 58%, black),
    /* radial-gradient(rgba(255,255,255,0.03), transparent 65%, transparent); */
    /* radial-gradient(transparent, transparent 58%, black); */
        /* radial-gradient(transparent, transparent 68%, black), */
        linear-gradient(to right, rgba(255,255,255,0.02), rgba(255,255,255,0.02)),
        linear-gradient(to right, rgba(0,0,0,0.1), rgba(0,0,0,0) 20%, rgba(0,0,0,0.1) 28%, rgb(0,0,0,1) 32%, rgb(0,0,0,1) 68%, rgba(0,0,0,0.1) 72%, rgba(0,0,0,0) 80%, rgba(0,0,0,0.1));
    box-shadow: 0 0 3vw rgba(0, 0, 0, 0.4), inset 0 0 1vw 0.5vw rgba(255,255,255, 0.08)
}

.asi-knob-overlay {
    /* border: 1px solid #ddd; */
    border-radius: 4%;
    position: absolute;
    /* margin-top: 3%;
    margin-left: 3%; */
    height: 100%;
    width: 100%;
    z-index: 1000;
    background-color: transparent;
    background-image: linear-gradient(to top, rgba(255,255,255,0), rgba(255,255,255,0) 70%, rgba(255,255,255,0.3) 88%, rgba(0,0,0,0.3)),
    linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,0) 92%, rgba(0,0,0,0.3));
}

.asi-knob {
    /* border: 1px solid goldenrod; */
    /* background-color: aquamarine; */
    /* border-left: 1px solid rgba(0,0,0,0.3);
    border-right: 1px solid rgba(0,0,0,0.3); */
    position: relative;
    /* top: -5%; */
    /* left: 40%; */
    margin: auto;
    height: 98%;
    width: 24%;
    z-index: 998;
    clip-path: polygon(
        18% 0, /* left top */
        82% 0, /* right top */ 
        100% 100%, /* right bottom */
        0 100% /* left bottom */
    );
}


.asi-needle {
    /* border: 1px solid rgba(0,0,0,0.3); */
    /* border-left: 1px solid rgba(0,0,0,0.3);
    border-right: 1px solid rgba(0,0,0,0.3); */
    border-radius: 2%;
    /* position: relative; */
    /* top: -5%; */
    /* top: 0; */
    left: 42%;
    height: 40%;
    width: 100%;
    z-index: 998;
    background-color: rgb(30,30,30);
    /* box-shadow: 0 0 10px rgba(0 ,0, 0, 0.45); */
    /* transition: 0.3s; */
    /* background-image: linear-gradient(to top, rgba(255,255,255,0), rgba(255,255,255,0) 70%, rgba(255,255,255,0.5) 99%, rgba(0,0,0,0.4) 100%); */
}

.asi-wrap {
    position: relative;
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.4));
    z-index: 999;
    width: 100%;
    height: 100%;
  }

.asi-base {
    /* border: 1px solid rgba(0,0,0,0.3); */
    /* border-left: 1px solid rgba(0,0,0,0.3);
    border-right: 1px solid rgba(0,0,0,0.3); */
    border-radius: 4%;
    position: absolute;
    /* top: 3%; */
    left: 6%;
    margin: auto;
    height: 100%;
    width: 88%;
    z-index: 998;
    background-color: rgb(35,35,35);
    /* box-shadow: 0 0 10px rgba(0 ,0, 0, 0.45); */
    /* transition: 0.3s; */
    background-image: linear-gradient(to top, rgba(255,255,255,0), rgba(255,255,255,0) 88%, rgba(255,255,255,0.5) 97%, rgba(0,0,0,0.1) 98%, rgba(255,255,255,0) 100%);
    clip-path: polygon(
        12% 0, /* left top */
        88% 0, /* right top */ 
        100% 100%, /* right bottom */
        0 100% /* left bottom */
    );
}

.asi-border {
    /* border: 1px solid rgba(0,0,0,0.3); */
    /* border-left: 1px solid rgba(0,0,0,0.3);
    border-right: 1px solid rgba(0,0,0,0.3); */
    border-radius: 4%;
    position: absolute;
    /* top: -5%; */
    /* top: 0; */
    /* left: 42%; */
    margin: auto;
    height: 60%;
    width: 100%;
    z-index: 998;
    /* background-color: red; */
    /* background-color: rgba(0,0,0,0.2); */
    /* box-shadow: 0 0 10px rgba(0 ,0, 0, 0.45); */
    /* transition: 0.3s; */
    /* box-shadow: inset 0 0 50px black; */
    background-image: linear-gradient(to top, rgba(255,255,255,0), rgba(255,255,255,0) 88%, rgba(255,255,255,0.5) 97%, rgba(0,0,0,0.1) 98%, rgba(255,255,255,0) 100%),
    linear-gradient(to right, rgba(0,0,0,0.4), rgba(255,255,255,0) 10%, rgba(255,255,255,0) 90%, rgba(0,0,0,0.4));
}

.asi-light {
    /* border: 2px solid rgb(0, 255, 34); */
    border-radius: 4%;
    background-color: rgb(255, 255, 255);
    position: relative;
    /* left: 32%; */
    margin: auto;
    height: 90%;
    width: 16%;
    z-index: 999;
    background-image: linear-gradient(to bottom,  rgba(226,226,226,0.1) 0%,rgba(219,219,219,0.1) 50%,rgba(209,209,209,0.1) 51%,rgba(254,254,254,0.1) 100%);
    box-shadow: inset 0 0 3px rgba(0 ,0, 0, 0.15), rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset rgb(160, 160, 160) 0 -1px 9px, rgb(255, 255, 255) 0 0px 8px, rgba(255, 255, 255, 0.85) 0 2px 40px;
}