.questions-enter {
    opacity: 0;
    transform: translateX(100%);
}
.questions-enter-active {
    opacity: 1;
    transform: translateX(0%);
}
.questions-exit {
    opacity: 1;
    transform: translateX(0%);
}
.questions-exit-active {
    opacity: 0;
    transform: translateX(-100%);
}
.questions-enter-active, .questions-exit-active {
    transition: opacity 250ms, transform 250ms;
}