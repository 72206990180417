.tc-step-enter {
    opacity: 0;
    transform: translateX(100%);
}
.tc-step-enter-active {
    opacity: 1;
    transform: translateX(0%);
}
.tc-step-exit {
    opacity: 1;
    transform: translateX(0%);
}
.tc-step-exit-active {
    opacity: 0;
    transform: translateX(-100%);
}
.tc-step-enter-active, .tc-step-exit-active {
    transition: opacity 250ms, transform 250ms;
}